// var isLive = false;
var isLive = true;
var BASE_API_URL = "";
var Rezorpay_Key = "";

if (isLive) {
  BASE_API_URL = "https://api.gudfynd.com"; //  Live Api
  Rezorpay_Key = "rzp_live_fwYrNFM64pVSx4"; //  Live Key
} else {
  BASE_API_URL = "https://gydfyndapi.buydirectories.com"; //  Local Api
  Rezorpay_Key = "rzp_test_ZX6pWxqbrkYrxn"; //  Local Key
}

export { BASE_API_URL, Rezorpay_Key };
